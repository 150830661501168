import { configureStore, combineReducers, getDefaultMiddleware, EntityAdapter } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import debug from 'debug';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  useStore as useReduxStore,
} from 'react-redux';
/* eslint-disable import/no-cycle */
import queuesReducer from './queues-slice';
import positionsReducer from './positions-slice';
import locationsReducer from './locations-slice';
import occupanciesReducer from './occupancies-slice';
import usersReducer from './users-slice';
/* eslint-enable import/no-cycle */

const rootReducer = combineReducers({
  queues: queuesReducer,
  positions: positionsReducer,
  locations: locationsReducer,
  occupancies: occupanciesReducer,
  users: usersReducer,
});

export const middleware = getDefaultMiddleware<AppState>();

if (process.env.NODE_ENV === 'development') {
  const log = debug('redux');

  const logger = createLogger({ logger: { log } });

  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = (...args: Parameters<typeof useReduxDispatch>) => useReduxDispatch<AppDispatch>(...args);
export const useSelector = (...args: Parameters<typeof useReduxSelector>) => useReduxSelector<AppState>(...args);
export const useStore = (...args: Parameters<typeof useReduxStore>) => useReduxStore<AppState>(...args);

export function setOne<T>(adapter: EntityAdapter<T>, state: any, item: T, key: string) {
  adapter.removeOne(state, key);
  adapter.addOne(state, item);
  return adapter;
}

export default store;
